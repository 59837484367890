:root {
  --background: #e5e5e5;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    -webkit-tap-highlight-color: transparent;
  }
  button:focus {
    outline: 0;
  }
}

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    /* Feel free to uncomment if you need to use gradient border */
    /* .border-gradient {
      @apply border border-transparent;
      background-image: linear-gradient(#fff8e4, #fff8e4), linear-gradient(#d0011b, #ffbd34);
      background-origin: border-box;
      background-clip: content-box, border-box;
    } */
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-size-adjust: none;
  @apply bg-background font-sans select-none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Regular.woff') format('woff'), url('/fonts/Shopee2021-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Medium.woff') format('woff'), url('/fonts/Shopee2021-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Bold.woff') format('woff'), url('/fonts/Shopee2021-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}
